import React from "react"
import { HeroWrapper } from "./HeroSection.styles"
import { Row, Col, Container } from "react-bootstrap"
import ApButton from "../../Global/Common/ApButton/ApButton"
import HeroImage from "./HeroImage"
import { navigateTo } from "../../../utils/hooks"
import { LINKS } from "../../../data/pageLinks.data";
import { Link } from "gatsby"

const HeroSection = () => {
  return (
    <HeroWrapper Tag="section">
      <Container
        className="py-lg-5 pt-4 pb-5 d-flex flex-column justify-content-around"
        fluid
      >
        <Row>
          <Col
            className="d-flex flex-column justify-content-center align-items-baseline px-4 py-4 py-lg-0"
            lg={{ offset: 1, span: 6 }}
          >
            <div className="mb-4 mt-0">
              <h1 className="text-uppercase text-white ambit font-weight-bold">Global BNPL Connectivity</h1>
            </div>
            <p className="mb-4 banner-text">
              Drive up conversion rates and basket values and improve your consumers’ experience across your global footprint without the need for local entities.
            </p>
            <p className="mb-4 banner-text">
              APEXX Connect is your single access point to a world of smarter commerce.
            </p>
            <Link to={LINKS.contactUs} className="learn-more">Learn More</Link>
          </Col>
          <Col lg={{ offset: 1 }} className="d-none d-lg-block pr-0 px-0">
          </Col>
          
        </Row>
      </Container>
      <div className="line item-1"></div>
      <div className="line item-2"></div>
      <div className="line item-3"></div>
      <HeroImage />
    </HeroWrapper>
    
  )
}

export default HeroSection
