import styled from "styled-components"

export const BnplVideoWrapper = styled.section`
  .vimeo-container{
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    .vimeo-player{
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      border-radius: 35px;
    }
  }
  @media screen and (max-width: 767px){
    display: none;
  }
`