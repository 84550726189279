import React from "react"
import { BuyNowPayLaterWrapper } from "./BuyNowPayLater.styles"
import { Container, Row, Col } from "react-bootstrap"
const BuyNowPayLater = () => {
  return (
    <BuyNowPayLaterWrapper className="py-5 px-4 px-lg-0 buy-now-pay-later">
      <Container>
            <h2 className="h1 font-weight-bold primary-blue-text mt-0 mb-3 mt-lg-5 ambit">
              Buy Now Pay Later
            </h2>
            <h3 className="h3 font-weight-normal mt-lg-5 mb-5 mt-4 nunito primary-blue-text">
              BNPL is a transformative but fragmented landscape for E-commerce merchants
            </h3>
            <Row>
              <Col lg={6} className="bnpl-cards text-left px-0 pl-3 px-lg-3 my-3">
                <div className="card-inner py-4 px-5 h-100">
                  <h4 class="h5 primary-blue-text font-weight-bold mb-4 mt-4 ambit">APEXX CONNECT</h4>
                  <p className="mb-4">
                    Without proper orchestration, Merchants offering multiple BNPL solutions
                    at checkout face significant integration, maintenance and operations
                    overhead to manage 1:1 settlement and reconciliation sources.
                  </p>
                </div>
              </Col>
              <Col lg={6} className="bnpl-cards text-left px-0 pl-3 px-lg-3 my-3">
                <div className="card-inner py-4 px-5 h-100">
                  <h4 class="h5 primary-blue-text font-weight-bold mb-4 mt-4 ambit">Simplified End-to-End Processes</h4>
                  <p className="mb-4">
                    Merchants offering multiple BNPLs to cater for diverse customer bases, do so at great cost.
                    APEXX Connect offers a single integration point with managed technical upgrades and consolidated
                    reporting to ensure a multi-bnpl offering is done so efficiently and sustainably.
                  </p>
                </div>
              </Col>
              <Col lg={6} className="bnpl-cards text-left px-0 pl-3 px-lg-3 my-3">
                <div className="card-inner py-4 px-5 h-100">
                  <h4 class="h5 primary-blue-text font-weight-bold mb-4 mt-4 ambit">Standardized Reporting</h4>
                  <p className="mb-4">
                    Your BNPL Payment Performance is available to you in a single Analytics platform which gives
                    you the tools to benchmark your providers performance, understand who is bringing you and
                    your consumers the greatest value at checkout.
                  </p>
                </div>
              </Col>
              <Col lg={6} className="bnpl-cards text-left px-0 pl-3 px-lg-3 my-3">
                <div className="card-inner py-4 px-5 h-100">
                  <h4 class="h5 primary-blue-text font-weight-bold mb-4 mt-4 ambit">Single API to the World of BNPL</h4>
                  <p className="mb-4">
                    The world of BNPL is evolving faster than any other area of payments with regional and
                    national providers showing exceptional growth and increasing diversity of products.
                    Offering the right solution in the right market is a tangible competitive advantage
                    for the online retailer; expand your global horizons with APEXX Connect.
                  </p>
                </div>
              </Col>
              <Col lg={6} className="bnpl-cards text-left px-0 pl-3 px-lg-3 my-3">
                <div className="card-inner py-4 px-5 h-100">
                  <h4 class="h5 primary-blue-text font-weight-bold mb-4 mt-4 ambit">Respecting Consumer Loyalty</h4>
                  <p className="mb-4">
                    Your BNPL Payment Performance is available to you in a single Analytics platform which
                    gives you the tools to benchmark your providers performance, understand who is bringing
                    you and your consumers the greatest value at checkout.
                  </p>
                </div>
              </Col>
            </Row>
      </Container>
    </BuyNowPayLaterWrapper>
  )
}

export default BuyNowPayLater
