import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const PaymentImage = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "bnpl-connect" }
          name: { eq: "payment-image" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return <Image fluid={data.allFile.edges[0] && data.allFile.edges[0].node.childImageSharp.fluid} style={{position: "unset"}} />
}

export default PaymentImage
