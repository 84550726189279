import React from "react"
import {BnplVideoWrapper} from "./BnplVideo.styles"
import { Container, Row, Col } from "react-bootstrap"


const bnplVideo = () => {

    return(
        <BnplVideoWrapper className="pt-0 pt-lg-5 px-4 px-lg-0">
            <Container>
                <Row>
                    <Col>
                        <div className="vimeo-container">
                            <iframe class="vimeo-player" title="vimeo-player" src="https://player.vimeo.com/video/591503064?h=16da9a5f13" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>
        </BnplVideoWrapper>
    )
}

export default bnplVideo;