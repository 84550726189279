import styled from "styled-components"

export const BnplArchitectureWrapper = styled.section`

h4 span{
    display: inline-block;
    height: 0;
    line-height: 30px;
     &::after{
        content: '';
        height: 15px;
        width: 100%;
        background-color: #4CB9EF;
        opacity: 0.2;
        display: block;
        position: relative;
        top: -14px;
        z-index: -1;
    }
}

.font-ambit{
    font-family: Ambit;
}
`