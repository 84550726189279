import styled from "styled-components"

export const SimplifiedPaymentWrapper = styled.section`
  background-color: #3B5CA7;
  color: #FFF;
  p {
    font-weight: 200;
    color: #FFF;
  }

  .color-sky-blue{
    color: #6BE2E5;
  }
  .payment-logo-container{
    flex: 0 0 100%;
    margin: 0;
    .payment-logo{
      background-color: #FFF;
      border-radius: 10px;
      height: 100px;
      box-shadow: #04040425 0 0 6px 0;
      .gatsby-image-wrapper {
        max-width: 80%;
        margin: auto;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        max-height: 100%;
      }
    }
  }
`
