import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const BnplConnectDiagramDesktop = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "bnpl-connect" }
          name: { eq: "bnpl-connect-diagram-desktop" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return <Image fluid={data.allFile.edges[0] && data.allFile.edges[0].node.childImageSharp.fluid} className="d-none d-md-block"/>
}

export default BnplConnectDiagramDesktop
