import React from "react"
import Layout from "../components/Global/Layout/Layout"
import SEO from "../components/Global/SEO/seo"
import HeroSection from "../components/BnplConnect/HeroSection/HeroSection"
import BnplArchitecture from "../components/BnplConnect/BnplArchitecture/BnplArchitecture"
import BnplVideo from "../components/BnplConnect/BnplVideo/BnplVideo"
import BuyNowPayLaterBlue from "../components/BnplConnect/BuyNowPayLaterBlue/BuyNowPayLaterBlue"
import BuyNowPayLater from "../components/BnplConnect/BuyNowPayLater/BuyNowPayLater"
import SimplifiedPayment from "../components/BnplConnect/SimplifiedPayment/SimplifiedPayment"
import DemoMobileWrapper from "../components/BnplConnect/SimplifiedPayment/DemoMobileWrapper"
import BnplLogos from "../components/BnplConnect/BnplLogos/BnplLogos"
import Testimonials from "../components/BnplConnect/Testimonials/Testimonials";

const BnplConnect = () => {
  return (
    <Layout
      headerAbsolute
      headerTransparent
      headerMobile={'blueGradient'}
      whiteToggler
      textlight
      logoDark={false}
      helpSectionColor={'white-bg'}
    >
     {/*  <SEO page="global-aggregator" /> */}
      <SEO page="bnpl-connect" />
      <HeroSection />
      <BnplLogos />
      <BnplArchitecture />
      <BnplVideo />
      <BuyNowPayLater />
      <BuyNowPayLaterBlue />
      <Testimonials />
      <SimplifiedPayment />
    </Layout>
  )
}

export default BnplConnect
