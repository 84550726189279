import React from "react"
import { BnplArchitectureWrapper } from "./BnplArchitecture.styles"
import { Container, Row, Col } from "react-bootstrap"
import BnplConnectDiagramDesktop from "./BnplConnectDiagramDesktop"
import BnplConnectDiagramMobile from "./BnplConnectDiagramMobile"

const BnplArchitecture = () => {

  return (
    <BnplArchitectureWrapper className="pt-5 px-4 px-lg-0">
      <Container className="pb-0 pt-md-5 pt-3 pb-md-5 px-3">
      <h2 className="h1 font-weight-bold primary-blue-text d-block ambit text-center">BNPL Connect Architecture</h2>
      <h3 className="h4 primary-blue-text mt-3 mb-5 d-block text-center"> A curated ecosystem covering 40+ markets</h3>
        <Col xl={12} className="text-center p-0 my-5">
            <BnplConnectDiagramDesktop/>
            <BnplConnectDiagramMobile className="DiagramMobileWrapper"/>
        </Col>
        <Row>
            <Col xl={4} md={6} sm={12} className="text-left px-0 pr-xl-5 mt-4 mt-md-5 pr-md-3">
              <div className="d-inline-block">
                <h4 class="h5 primary-blue-text ambit font-weight-bold"><span>SINGLE API</span></h4>
                <p className="my-0">
                  APEXX Connect makes the world of BNPL available through a single API Integration.
                  Adding new providers and products becomes a configuration exercise not a development one.
                </p>
              </div>
            </Col>
            <Col xl={4} md={6} sm={12} className="text-left px-0 px-xl-4 mt-4 mt-md-5 pl-md-3 pr-md-0">
              <h4 class="h5 primary-blue-text ambit font-weight-bold"><span>MULTI INTEGRATION&nbsp;</span><span>METHODS</span></h4>
              <p className="my-0">
                APEXX Connect is available via server to server, hosted payment page,
                hosted field and client-side encryption integration methods.
              </p>
            </Col>
            <Col xl={4} md={6} sm={12} className="text-left px-0 pl-xl-5 pr-xl-0 mt-4 mt-md-5 pr-md-3 pl-md-0">
              <h4 class="h5 primary-blue-text ambit font-weight-bold"><span>MANAGED&nbsp;</span><span>UPGRADES</span></h4>
              <p className="my-0">
                APEXX will ensure all major upgrades to providers’ solutions and platforms are
                delivered on APEXX Connect reducing the maintenance burden for our customers.
              </p>
            </Col>
            <Col xl={4} md={6} sm={12} className="text-left px-0 pr-xl-5 pl-xl-0 mt-4 mt-md-5 pl-md-3 pr-md-0">
              <h4 class="h5 primary-blue-text ambit font-weight-bold"><span>SIMPLIFIED&nbsp;</span><span>RECONCILIATION</span></h4>
              <p className="my-0">
                APEXX will consolidate settlement reports from each integrated BNPL and provide
                our customers with a single standardized file to ringfence operational efficiency.
              </p>
            </Col>
            <Col xl={4} md={6} sm={12} className="text-left px-0 px-xl-4 mt-4 mt-md-5 pr-md-3 pl-md-0">
              <h4 class="h5 primary-blue-text ambit font-weight-bold"><span>DIRECT&nbsp;</span><span>CONNECTIONS</span></h4>
              <p className="my-0">
                  All BNPL connections offered by APEXX Connect are direct to the BNPL.
                  there are no indirect connections present to any of the BNPLs reducing cost
                  and friction for merchants and partners.
              </p>
            </Col>
        </Row>
      </Container>
    </BnplArchitectureWrapper>
  )
}

export default BnplArchitecture