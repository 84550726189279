import styled from "styled-components"

export const PaymentDemoWrapper = styled.div`
background: #FFF;
min-width: 272px;
width: 100%;
max-width: 350px;
height: auto;
height: 500px;
border-radius: 0px 0px 25px 25px;
box-shadow: 0 1px 6px 0 #a4a4a470;
.payment-demo-image{
  height: 50%;
  .image-dim{
    background-color: #4C4A4AB3;
    height: 100%;
    position: relative;
    bottom: 100%;
  }
  .gatsby-image-wrapper{
    height: 100%;
  }
}
.btn-green{
  border-radius: 50px;
  width: 100%;
  font-size: 0.8rem;
  line-height: 2.25;
}
.add-cc{
  width: 100%;
  background-color:#F3F3F3;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 2.25;
  border-radius: 50px;
  .cc-icon{
    height: 15px;
    width: 20px;
    margin-left: 5px;
    display: inline-block;
    top: 2px;
    position: relative;
  }
  .cc-label{
    width: calc(100% - 25px);
    display: inline-block;
  }
}
.demo-or{
  color: #B4B4B4;
  font-size: 0.9rem;
}
.cc-text{
  position: relative;
  bottom: 115%;
  z-index: 15;
  color: #FFF;
  width: 100%;
  font-size: 18px;
  span{
    float: right;
  }
}
`
