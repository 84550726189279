import React from "react"
import { BuyNowPayLaterBlueWrapper } from "./BuyNowPayLaterBlue.styles"
import { Container, Row, Col } from "react-bootstrap"
import HeadingWithBg from "../../Global/Common/HeadingWithBg/HeadingWithBg"
const BuyNowPayLaterBlue = () => {
  return (
    <BuyNowPayLaterBlueWrapper className="py-5 px-4 px-lg-0 buy-now-pay-later">
      <Container className="py-3">
        <HeadingWithBg className="blue-bnpl-label" text="Buy Now Pay Later" />
          <Row className="align-items-center">
            <Col lg={6} className=" text-lg-left pr-lg-5 pb-5 pb-lg-3">
              <h2 class="h1 color-white font-weight-bold ambit"><span className="apexx-connect-title">APEXX Connect</span> gives merchants the power to overcome their <span className="apexx-connect-title">BNPL</span> challenges</h2>
            </Col>
            <Col lg={6} className=" text-lg-left pl-lg-5 pb-5 pb-lg-3">
              <Row>
                <Col xs={12} className="pb-3">
                  <h4 class="h5 apexx-connect-title font-weight-bold ambit">CONSOLIDATED EVERGREEN API</h4>
                  <p>
                    Merchants will connect to a single API with zero field replication to access all
                    current and future BNPL propositions available on APEXX Atomic. BNPL providers
                    are rapidly scaling and enriching their product propositions, APEXX will manage
                    a continuous upgrade roadmap to ensure Merchants have an up to date connection.
                  </p>
                </Col>
                <Col xs={12} className="pb-3">
                  <h4 class="h5 apexx-connect-title font-weight-bold ambit">CUSTOMER EXPERIENCE & MERCHANT INSIGHT</h4>
                  <p>
                    APEXX Connect facilitates Plug’n’Play enablement of new providers and products to help Merchants
                    stay abreast of evolving geographic and demographic consumer trends and preferences.
                  </p>
                  <p>
                    Our advanced BNPL Analytics enriches the merchant experience with consolidated, transparent,
                    performance reporting giving you the tools to compare your providers, optimize for cost and
                    conversion and deliver the best possible checkout experience.
                  </p>
                </Col>
                <Col xs={12} className="pb-3">
                  <h4 class="h5 apexx-connect-title font-weight-bold ambit">SIMPLIFIED RECONCILIATION</h4>
                  <p>
                    APEXX Connect also safeguards operational efficiency when launching a multi-bnpl offering by
                    consolidating settlement files into a single standardized report that meets the needs of our customer.
                  </p>
                </Col>
              </Row>

            </Col>
          </Row>
      </Container>
    </BuyNowPayLaterBlueWrapper>
  )
}

export default BuyNowPayLaterBlue
