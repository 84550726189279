import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import {BnplDiagramMobileWrapper} from "./BnplDiagramMobile.styles"

const BnplConnectDiagramMobile = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "bnpl-connect" }
          name: { eq: "bnpl-connect-diagram-mobile" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <BnplDiagramMobileWrapper>
      <Image fluid={data.allFile.edges[0] && data.allFile.edges[0].node.childImageSharp.fluid} className="d-block d-md-none"/>
    </BnplDiagramMobileWrapper>
  )
}

export default BnplConnectDiagramMobile
