import styled from "styled-components"

export const BuyNowPayLaterBlueWrapper = styled.section`
  background-color: #11374B;
  color: #FFF;
  p {
    color: #FFF;
    font-weight: 200;
  }
  .blue-bnpl-label{
    color: #FFF!important;
  }
  .apexx-connect-title{
    color: #6BE2E5;
  }
`
