import styled from "styled-components"

export const HeroWrapper = styled.section`
  height: 100vh;
  min-height: 600px;
  width: 100%;
  position: relative;
  @media (max-width: 991px) {
    min-height: auto;
    height: auto;
  }
  .container-fluid{
    padding-left: 0;
    padding-right: 0;
    background: linear-gradient(90deg, #84E7F0 0%, #78AAEE 100%), #144056;
    background-blend-mode: overlay, normal;
    clip-path: polygon(100% 0%, 0% 0%, 0% 100%, calc(100% - 720px) calc(47% + 240px), calc(100% - 480px) calc(47% + 120px), calc(100% - 240px) calc(47% + 120px), 100% 47%);
    position: relative;
    z-index: 2;
    height: 100%;
    @media (max-width: 1440px),(max-height: 880px) {
      clip-path: polygon(100% 0%, 0% 0%, 0% 100%, calc(100% - 510px) calc(47% + 170px), calc(100% - 340px) calc(47% + 85px), calc(100% - 170px) calc(47% + 85px), 100% 47%);
    }
    @media (max-width: 991px) {
      clip-path: none;
    }
    .row{
      margin-left: 0;
      margin-right: 0;
    }
    .learn-more{
      color: #FFF;
      background: #6ECDD6;
      padding: .375em 2em;
      border-radius: 10px;
      margin-top: 27px;
      @media (max-width: 991px) {
        margin-top: 22px;
      }
    }
    .banner-text{
      font-size: 1.25em;
    }
  }

  .line{
    height: 2px;
    width: 240px;
    background-color: #55B9A3;
    position: absolute;
    z-index: 2;

    &::before{
      content: '';
      width: 13px;
      height: 13px;
      display: block;
      position: relative;
      border-radius: 50%;
    }

    &::after{
      content: '';
      width: 13px;
      height: 13px;
      display: block;
      position: relative;
      border-radius: 50%;
    }

    &.item-1{
      top: calc(47% - 25px);
      right: 12px;
      transform-origin: right;
      transform: rotate(-26.565deg);

      &::before{
        right: 19px;
        bottom: 6px;
        background-color: #FBCD76;
      }

      &::after{
        bottom: 19px;
        left: calc(100% + 7px);
        background-color: #3BB8C2;
      }
    }

    &.item-2{
      width: 220px;
      top: calc(47% + 89px);
      right: 250px;

      &::before{
        right: 19px;
        bottom: 6px;
        background-color: #AFDCFC;
      }

      &::after{
        width: 0;
        height: 0
      }
    }

    &.item-3{
      width: 245px;
      top: calc(47% + 93px);
      right: 494px;
      transform: rotate(-26.565deg);
      transform-origin: right;

      &::before{
        right: 19px;
        bottom: 6px;
        background-color: #FFACEA;
      }

      &::after{
        width: 0;
        height: 0
      }
    }

    @media (max-width: 1440px),(max-height: 880px) {

      width: 170px;

      &.item-2{
        width: 142px;
        top: calc(47% + 57px);
        right: 188px;
      }

      &.item-3{
        width: 170px;
        top: calc(47% + 60px);
        right: 354px;
      }
    }

    @media (max-width: 991px) {
      display: none;
    }
  }

  .gatsby-image-wrapper{
    position: absolute !important;
    width: 720px;
    top: 47%;
    right: 0;
    @media screen and (max-width: 1440px),(max-height: 880px) {
      width: 510px;
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  h1 {
    @media (min-width: 992px) {
      font-size: 4rem;
    }
  }
  svg {
    min-width: 60px;
  }

  .title-blue-text{
    color: #45B8F1;
  }

  p,
  span {
    color: #edf0f2;
    span {
      font-weight: bold;
    }
  }
  .product-image {
    width: 230px;
    &:last-child {
      width: 200px;
    }
  }
  .explore-button {
    @media (max-width:992px) {
      width: 100%;
    }
  }
`
