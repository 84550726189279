import React from "react"
import { SimplifiedPaymentWrapper } from "./SimplifiedPayment.styles"
import { Container, Row, Col } from "react-bootstrap"
import SimplifiedPaymentDemo from "./SimplifiedPaymentDemo"
import PaymentImage from "./PaymentImage"

const SimplifiedPayment = () => {
  return (
    <SimplifiedPaymentWrapper className="py-0 py-lg-5 px-4 px-lg-0 buy-now-pay-later">
      <Container className="py-3">
        <Row>
          <Col lg={7} className="text-center text-lg-left pr-lg-5 pb-0">
            <h2 className="h1 font-weight-normal my-5 ambit">
              The future of <span className="font-weight-bold color-sky-blue">APEXX Connect</span>
            </h2>
            <h3 className="h4 font-weight-normal my-5 poppins">
             A roadmap designed for optimization and enhanced merchant control.
            </h3>
            <h4 className="h5 font-weight-bold mt-4 mb-2 color-sky-blue ambit">
              Pay Later Button
            </h4>
            <p>
              APEXX Aggregator allows Merchants to embed a single ‘Pay Later’ button
              to drastically improve checkout and payment page design where multiple
              BNPL products are available to a customer.
            </p>
            <h4 className="h5 font-weight-bold mt-4 mb-2 color-sky-blue ambit">
              Customised Presentment
            </h4>
            <p>
              Product presentment may also be dictated based on transaction value,
              volume allocation, cost, region, time, consumer preference
              (based on historical data) and probability of acceptance
            </p>
            <h4 className="h5 font-weight-bold mt-4 mb-2 color-sky-blue ambit">
              Collection Services
            </h4>
            <p>
              Building upon our settlement file consolidation, APEXX will soon be
              offering our customers collection services to simplify the flow of
              funds from their chosen BNPL providers
            </p>
          </Col>
          <Col lg={5} className="d-flex">
            {/* <div>
            <PaymentImage/>
            </div> */}
            <div className="h-100 w-100 p-0 align-items-center justify-content-center py-5 d-flex">
              <Col xs={9} lg={12} className="p-0 mx-auto w-100">
                  <PaymentImage/>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </SimplifiedPaymentWrapper>
  )
}

export default SimplifiedPayment
