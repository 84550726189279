import styled from "styled-components"

export const BuyNowPayLaterWrapper = styled.section`
  p {
    font-weight: 200;
  }
  .bnpl-cards{
    .card-inner{
      background: linear-gradient(180deg, rgba(85, 185, 163, 0.16) 0%, rgba(143, 197, 234, 0.4) 100%);
      border-radius: 15px;
    }
  }
  @media screen and (max-width: 767px){
    h3{
      font-size: 1.25em;
    }
  }
`
