import styled from "styled-components";
import { colors } from "../../../utils/styles"

export const TestimonialsWrapper = styled.section`
    background: linear-gradient(180deg, rgba(85, 185, 163, 0.16) 0%, rgba(143, 197, 234, 0.4) 100%);
    position: relative;

    h2{
        color: ${colors.primaryBlue};
    }

    .bg-image{
        position: absolute;
        height: 100%;
        width: 100%;
    }

    

    .container{
        .testimonial-copy{
            font-size: 1.25rem;

            @media (max-width: 991px){
                font-size: 1rem;
            }
        }
        h2.h1{
            @media (max-width: 991px){
                font-size: 1.75rem;
            }
        }
        .live-tag{
            background-color: #B2FCE3;
            color: #6C6C6C;
            border-radius: 50px;
            padding: 2px 38px;
            margin-left: 30px;
            position: absolute;
            top: 0;
        }
        .slick-dots{
            li{
                button{
                    &::before{
                        background-color: #55B9A3;
                        content: '';
                        width: 13px;
                        height: 13px;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
`;