import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TestimonialsWrapper } from "./TestimonialsStyles"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import BackgroundImage from "./BackgroundImage"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {

    const data = useStaticQuery(graphql`
        {
            allFile(filter: {relativeDirectory: {eq: "paymentOrchestrationLayer/Testimonials/SliderImages"}}) {
                edges {
                node {
                    childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                    }
                    name
                }
                }
            }
        }`
    )

    const imageItems = {};    

    data.allFile.edges.map(item =>{
        imageItems.[item.node.name] = item;
    });

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      };

    return (
        <TestimonialsWrapper>
            <BackgroundImage/>
            <Container className="py-5 px-lg-0">
                        <Slider {...settings}>
                        <div>
                            <Row className="px-5 px-lg-3 my-5 flex-lg-row flex-column-reverse">
                                <Col lg={5} xs={12} className="px-3 mt-4 mt-lg-0 d-flex">
                                    <img src={imageItems.marksElectrical.node.childImageSharp.fluid.src} alt={imageItems.marksElectrical.node.name} style={{maxWidth: "300px"}} className="my-auto mx-auto mx-lg-0" />
                                </Col>
                                <Col lg={7} xs={12} className="testimonial-copy px-3 px-lg-5">
                                    <img src={imageItems.clearpay.node.childImageSharp.fluid.src} alt={imageItems.clearpay.node.name} style={{maxWidth: "200px"}} className="mb-4 d-inline-block" />
                                    <span className="live-tag font-weight-bold d-none d-md-inline-block">LIVE</span>
                                <p>
                                    Initial engagement to go LIVE was only <span className="font-weight-bold">3 weeks</span>.
                                    &nbsp; <span className="font-weight-bold">Clearpay</span> partnership has driven <span className="font-weight-bold">90%+</span> new to file customers for <span className="font-weight-bold">Marks Electrical</span>.
                                </p>
                                <p>
                                    <span className="font-weight-bold">Clearpay</span> is their leading BNPL, contributing to over <span className="font-weight-bold">10%</span> of overall checkout.
                                </p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row className="px-5 px-lg-3 my-5 flex-lg-row flex-column-reverse">
                                <Col lg={5} xs={12} className="px-3 mt-4 mt-lg-0 d-flex">
                                    <img src={imageItems.marksElectrical.node.childImageSharp.fluid.src} alt={imageItems.marksElectrical.node.name} style={{maxWidth: "300px"}} className="my-auto mx-auto mx-lg-0" />
                                </Col>
                                <Col lg={7} xs={12} className="testimonial-copy px-3 px-lg-5">
                                    <img src={imageItems.clearpay.node.childImageSharp.fluid.src} alt={imageItems.clearpay.node.name} style={{maxWidth: "200px"}} className="mb-4 d-inline-block" />
                                    <span className="live-tag font-weight-bold d-none d-md-inline-block">LIVE</span>
                                <p>
                                    Initial engagement to go LIVE was only <span className="font-weight-bold">3 weeks</span>.
                                    &nbsp; <span className="font-weight-bold">Clearpay</span> partnership has driven <span className="font-weight-bold">90%+</span> new to file customers for <span className="font-weight-bold">Marks Electrical</span>.
                                </p>
                                <p>
                                    <span className="font-weight-bold">Clearpay</span> is their leading BNPL, contributing to over <span className="font-weight-bold">10%</span> of overall checkout.
                                </p>
                                </Col>
                            </Row>
                        </div>
                        </Slider>
            </Container>
        </TestimonialsWrapper>
    );
}

export default Testimonials